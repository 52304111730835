
.Login-content {
  margin: 1em auto 1em auto;
  padding: 10px;
}

.Login-content, input {
  margin-bottom: 16px;
}

